.country-select {
  width: auto;
}

.LinkForm {
  max-width: 600px;
  margin: 0 auto;
}

.globaly-alert {
  max-width: 600px;
  margin: 0 auto;
}

.LinkForm .btn {
  margin: 5px auto;
  display: block;
}

.link-form-item {
  margin-bottom: 20px;
  display: flex;
  max-width: 95%;
  margin: 0 auto;
}

.link-form-item input[type=text] {
  flex: 1;
}

.link-form-item > * {
  margin: 7px;
}

.label-wrapper {
  display: flex;
  text-align: center;
}

.label-wrapper label {
  padding: 10px;
}

.space-fill-label {
  flex: 1;
}
